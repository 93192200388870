import React, { useEffect } from 'react';

import Aos from 'aos';
import "aos/dist/aos.css";

import Line from '../../Line/Line';

import MAG_Beurteilung from '../../Bilder Screenshots/MAG/Beurteilung.jpg';

import './ModulesG.css';

const Prozesse = (props) =>{
    useEffect(() =>{
        Aos.init({
            duration: 2000,
            once: true,
        });
    }, []);
    return(
        <div className="module_container_first">
            <div className="module_title_container_box">
                <h1 className="module_title_first">Prozesse</h1>
                <Line />
            </div>
            <div className="module_image_container">
                <img data-aos="fade-up" className="module_image_default" src={MAG_Beurteilung} alt="Bild1"/>
            </div>
        </div>
    );
}

export default Prozesse;