import React, { useEffect } from 'react';
import Aos from 'aos';
import "aos/dist/aos.css";

import { HashLink } from 'react-router-hash-link';

import "./Module.css";

import MAG_Beurteilung from '../../Bilder Screenshots/MAG/Beurteilung.jpg';

import Verg_HrPortal from '../../Bilder Screenshots/Vergütungsmanagement/MyAbacus HR Portal ESS MSS  Vergütungsrmanagement Direktunterstellte.png';

import Zeit_Absenzuebersicht from '../../Bilder Screenshots/Zeit/Absenzübersicht.jpg';

import Line from '../../Line/Line';

const Home = (props) =>{
    useEffect(() =>{
        Aos.init({
            duration: 2000,
            once: true,
        });
    }, []);
    return(
        <div className="Module_container">
            <div className="Modules_container">
                <ul className="Modules_list">
                    <li><HashLink className="module_link" smooth to="/Module#MAG">MAG</HashLink></li>
                    <li><HashLink className="module_link" smooth to="/Module#VER">Vergütungsmanagement</HashLink></li>
                    <li><HashLink className="module_link" smooth to="/Module#ZEIT">Zeit</HashLink></li>
                </ul>
                <div id="MAG"></div>
                <div data-aos="fade-up" className="Module">
                    <h2>MAG</h2>
                    <Line />
                    <img className="Module_image" src={MAG_Beurteilung} alt="Beurteilung" />
                </div>
                <div id="VER"></div>
                <div data-aos="fade-up" className="Module">
                    <h2>Vergütungsmanagement</h2>
                    <Line />
                    <img className="Module_image" src={Verg_HrPortal} alt="Beurteilung" />
                </div>
                <div id="ZEIT"></div>
                <div data-aos="fade-up" className="Module">
                    <h2>Zeit</h2>
                    <Line />
                    <img className="Module_image" src={Zeit_Absenzuebersicht} alt="Beurteilung"/>
                </div>
            </div>
            <div className="back-To-Top show" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} >
                ^
            </div>
        </div>
    );
}

export default Home;