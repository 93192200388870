import React, { useEffect } from 'react';

import Aos from 'aos';
import "aos/dist/aos.css";

import Line from '../../Line/Line';

import './ModulesG.css';

import p1 from '../../Bilder Screenshots/Payroll/Abacus Payroll Menü.jpg';
import p2 from '../../Bilder Screenshots/Payroll//Abacus Payroll Verwaltung Adressen.jpg';
import p3 from '../../Bilder Screenshots/Payroll//Abacus Payroll Verwaltung Kinder.jpg';
import p4 from '../../Bilder Screenshots/Payroll/Abacus Payroll Verwaltung Lohn.jpg';
import p5 from '../../Bilder Screenshots/Payroll/Abacus Payroll Verwaltung Versicherungen.jpg';
import p6 from '../../Bilder Screenshots/Payroll/Abacus Payroll Verwaltung Kontos.jpg';
import p7 from '../../Bilder Screenshots/Payroll/Abacus Payroll Verwaltung Ein und Austritt.jpg';

const Payroll = (props) =>{
    useEffect(() =>{
        Aos.init({
            duration: 2000,
            once: true,
        });
    }, []);
    return(
        <div className="module_container_first">
            <div className="module_title_container_box">
                <h1 className="module_title_first">Payroll</h1>
                <Line />
            </div>
            <div className="module_span_text">
                <span name="body">
                    <p>
                        Die Abacus <b>Personalverwaltung</b> mit dem übersichtlichen Personalstamm ist ein starker Pfeiler der Abacus Lohnsoftware.
                        Daten zu Personalien, Anstellungen, Lohn, Versicherungen, Bankverbindungen und vieles mehr können Sie jederzeit abrufen und datumsbezogen anpassen.
                    </p>
                    <p>
                        Im Personalstamm integriert ist eine prozessunterstützte Aufgaben- und Dossierverwaltung für die Ablage von Lohnabrechnungen, Arbeitsverträgen, Arbeitszeitprotokollen usw.
                        Einfach anpassbare Personallisten, vordefinierte Stammblätter und eine flexible Massenänderung unterstützen Sie bei der täglichen Arbeit.
                    </p>

                    <br />

                    <p>
                        Die leistungsstarke Abacus <b>Lohnbuchhaltung</b> ist schweizweit erfolgreich im Einsatz. Es ist die verlässliche Software für Lohnverarbeitungen mit periodisch wiederkehrenden
                        Arbeiten für die Auszahlung der Löhne und Gehälter. Dazu gehört die Vorerfassung periodischer Daten, die Abrechnung und Korrektur der Saläre, die Auszahlungen und das
                        Versenden von Lohnabrechnungen.
                    </p>
                    <p>
                        Die Arbeit erleichtert dabei vor allem ein hoher Automatisierungsgrad. Während innerhalb weniger Minuten tausende von Lohnabrechnungen erstellt werden, können Sie trotzdem in
                        die laufende Abrechnung eines Mitarbeitersalärs eingreifen und Korrekturen vornehmen. Automatisierte Korrekturmöglichkeiten im Sinne einer Rückrechnung vergangener Perioden
                        sind ebenfalls möglich.
                    </p>
                    <p>
                        Die Lohnabrechnungen können individuell an die Corporate Identity des Unternehmens angepasst werden.
                    </p>

                    <br />
                    <p>
                        Das flexible Abacus <b>Reporting</b> mit seinem perfekten Zugriffsschutzsystem ermöglicht neben Lohnabrechnungen, Auszahlungslisten und Buchungsbelegen das Auswerten von abgerechneten
                        periodischen oder kumulativen Daten. Mit der Lohnsoftware exportieren Sie Worddokumente mit einer Serienbrieffunktion und füllen amtliche Formulare mit der integrierten
                        PDF-Funktion aus.
                    </p>
                </span>       
            </div>
            <div className="module_image_container">
                <img data-aos="fade-up" className="module_image_default" src={p1} alt="Bild1"/>
                <img data-aos="fade-up" className="module_image_default" src={p2} alt="Bild2"/>
                <img data-aos="fade-up" className="module_image_default" src={p3} alt="Bild3"/>
                <img data-aos="fade-up" className="module_image_default" src={p4} alt="Bild4"/>
                <img data-aos="fade-up" className="module_image_default" src={p5} alt="Bild5"/>
                <img data-aos="fade-up" className="module_image_default" src={p6} alt="Bild6"/>
                <img data-aos="fade-up" className="module_image_default" src={p7} alt="Bild7"/>
            </div>
            <div className="module_span_text">
                <h3 className="Modul_medium_title">Lohnbuchhaltungsfunktionalität</h3>
                <p>
                    Über eine Million Lohnabrechnungen werden jeden Monat mit der ABACUS Lohnbuchhaltung erstellt. Flexible Definitionsmöglichkeiten erlauben das Implementieren einer
                    massgeschneiderten Lohnbuchhaltung für Kunden jeder Grösse und Branche. Die Lohnbuchhaltung wird laufend gemäss den neusten gesetzlichen Richtlinien
                    swissdec-zertifiziert.
                </p>
                <h5 className="Modul_small_title">Personalstammdaten</h5>
                <ul>
                    <li>Adressdaten, Kinderstamm</li>
                    <li>Ein- und Austrittsverwaltung</li>
                    <li>Mitarbeiterfoto</li>
                    <li>Mehrere Zahlungsverbindungen pro Mitarbeitenden</li>
                    <li>Lohn- und Salärdaten</li>
                    <li>Lohnklassen und Lohnstufen</li>
                    <li>Anpassung von Lohndaten im Voraus auf bestimmtes Datum (Zeitachse)</li>
                    <li>Abbildung von Abteilungen und Betrieben und anderen Gruppierungselementen</li>
                    <li>Personaldaten via Schnittstelle ein- und auslesen</li>
                    <li>Integrierte Aufgaben- und Pendenzenverwaltung</li>
                    <li>Digitales Personaldossier</li>
                </ul>
                <br />
                <h5 className="Modul_small_title">Berechnungsgrundlagen</h5>
                <ul>
                    <li>Basis-Lohnartenstamm</li>
                    <li>Branchenspezifische Lohnartenstämme</li>
                    <li>Beliebig viele Lohnarten und Basen definierbar</li>
                    <li>Berechnungsformeln frei definierbar</li>
                    <li>Tabellen für komplexe Berechnungen</li>
                    <li>Globalanpassung der Lohndaten</li>
                    <li>Automatische Berechnung der Quellensteuer</li>
                    <li>Lohndaten via Schnittstelle ein- und auslesen</li>
                    <li>Korrekte Spesenberechnung inkl. MWST</li>
                </ul>
                <br />
                <h5 className="Modul_small_title">Lohnverarbeitung</h5>
                <ul>
                    <li>Variable Lohndaten auf beliebige Kostenstellen/-träger erfassbar</li>
                    <li>Online-/Batch-Lohnabrechnung</li>
                    <li>Iteration für Nettolohnberechnungen</li>
                    <li>Differenzzahlung bei Korrekturen nach erfolgter Erst-Auszahlung</li>
                    <li>Pro-Rata-Berechnungen</li>
                    <li>Korrektur und Löschung von Abrechnungen</li>
                    <li>Lohnauszahlung über ISO 20022, UBS KeyDirect, Vergütungsauftrag Bank / Post, Check, Barauszahlung</li>
                    <li>Unbeschränkte Anzahl Abrechnungen pro Monat</li>
                    <li>Frei definierbare Zwischenabrechnungen</li>
                    <li>Einzahlungen, Mehrfachzahlungen</li>
                    <li>Lohnauszahlungen in verschiedenen Währungen</li>
                    <li>Automatische Archivierung der Lohnabrechnungen</li>
                    <li>Stunden- und Ferienabrechnungen</li>
                    <li>Rückrechnung</li>
                </ul>
                <br />
                <h5 className="Modul_small_title">Geschäftsbereiche, Unternehmen</h5>
                <ul>
                    <li>Mehrere selbständig bilanzierende Einheiten innerhalb eines Mandanten führen</li>
                    <li>Mehrere Geschäftsbereiche pro Strukturelement ”Unternehmen” führen</li>
                    <li>Einheitliche Stammdaten für alle Geschäftsbereiche pro Unternehmung innerhalb eines Mandanten wie Geschäftsjahr, Konten-/Kostenstellenplan, MWST-Methode und -Periode etc.</li>
                    <li>Geschäftsbereichsübergreifende Mehrwertsteuerabrechnung auf Ebene des Unternehmens</li>
                    <li>Sozialversicherungsabrechnung pro Unternehmung anstatt auf Ebene des Mandanten</li>
                </ul>
                <br />
                <h5 className="Modul_small_title">Verbuchung</h5>
                <ul>
                    <li>Lohndaten verteilbar auf verschiedene Kostenstellen/-träger</li>
                    <li>Mitarbeiter fix auf beliebig viele Kostenstellen verteilbar</li>
                    <li>FIBU-Verbuchungsmatrix</li>
                    <li>Direkte Verbuchung in die FIBU oder via ASCII-Schnittstelle inkl. Buchungsbeleg</li>
                    <li>Mehrwertsteuerpflichtige Lohnarten Auswertungen</li>
                    <li>Lohnabrechnung ergänzbar mit textlichen Kommentaren</li>
                    <li>Lohnabrechnungen auch selektiv nach Abteilungen und Betrieben</li>
                    <li>Lohnabrechnungen via IncaMail versenden</li>
                    <li>Detaillierte Journale (Monats-, Perioden-, Kumulativjournale, Vorjahresvergleiche)</li>
                    <li>Frei definierbare Auswertungen mittels AbaReport</li>
                    <li>Einfach definierbare Stammdatenlisten</li>
                    <li>Frei definierbare Word-Serienbriefe</li>
                    <li>Formular-Einbindung von amtlichen PDF-Dokumenten, Jahresendauswertungen</li>
                    <li>Lohnausweis</li>
                    <li>AHV/ALV, SUVA-, FAK-, BVG-Abrechnungen</li>
                    <li>Quellensteuerabrechnungen</li>
                    <li>BFS-Statistik</li>
                    <li>FAR-Statistik (Flexibler Altersrücktritt)</li>
                    <li>UVGZ-Abrechnungen</li>
                    <li>KTG-Abrechnungen</li>
                    <li>Statistiken über beliebige Jahre</li>
                    <li>Revisionslisten für interne und externe Kontrolle</li>
                    <li>ELM-Integration</li>
                    <li>swissdec-zertifiziert (<a className="module_links" href="https://www.swissdec.ch/de/zertifizierte-software-hersteller/">https://www.swissdec.ch/de/zertifizierte-software-hersteller/</a>)</li>
                </ul>
            </div>
        </div>
    );
}

export default Payroll;