import React, {Component} from 'react';
import mailgun from 'mailgun-js';
import "./Kontakt.css";

import FormInput from "./FormInput/FormInput";
import FormButton from "./FormInput/FormButton";
import Line from "../../Line/Line";

class kontakt extends Component{
    constructor(props){
        super(props);
            this.state = {
                name: '',
                vorname: '',
                firma: '',
                funktion: '',
                email: '',
                nachricht: '',
                toaststatus: '',
                toastmessage: '',
            }
        this.handleInput = this.handleInput.bind(this);
        this.handleclicks = this.handleclicks.bind(this);
    };

    handleInput(e){
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleclicks(){
        let isFormValid = true;
        
        if(this.state.name === ""){
            isFormValid = false;
        }
        if(this.state.vorname === ""){
            isFormValid = false;
        }
        if(this.state.email === "" || !this.validateEmail(this.state.email)){
            isFormValid = false;
        }
        if(this.state.nachricht === ""){
            isFormValid = false;
        }

        if(isFormValid){
            /*const DOMAIN = 'sandbox562bdb9615e04e6cab98e6c3da0e66a9.mailgun.org';
            const api_key = "d1d6220993f661c0a2a422255ca627a0-3d0809fb-ba0bdcca";*/

            const DOMAIN = 'sandboxbf9252af35c447309608f825f2fb97e0.mailgun.org';
            const api_key = "7aa6856d2b693da4d1813a6091b587b9-90346a2d-813da0b7";
            const mg = mailgun({apiKey: api_key, domain: DOMAIN});

            const text = "Von: " + this.state.name + " " + this.state.vorname + "\nFirma: " + this.state.firma + "\nFunktion: " + this.state.funktion + "\nEmail: " + this.state.email + "\n\nNachricht:\n" + this.state.nachricht;
            
            const data = {
                from: "LepaCloud - Kontakt <" + this.state.email + ">",
                to: 'info@lepa.ch',
                subject: "LepaCloud - Kontakt",
                text: text,
            };

            this.setState({
                name: '',
                vorname: '',
                firma: '',
                funktion: '',
                email: '',
                nachricht: '',
            })

            mg.messages().send(data, (error, body)  => {
                console.log(body);
                if(error){
                    console.log(error);
                    this.showToast("warning", "Email nicht versendet");
                }
                this.showToast("success", "Email erfolgreich versendet");
            });
        }
        else{
            this.showToast("warning", "Formular invalid");
        }
    }

    showToast = (status, message) =>{
        this.setState({
            toaststatus: status,
            toastmessage: message,
        })

        this.sleep(2000).then(() =>{
            this.setState({
                toaststatus: '',
                toastmessage: '',
            })
        })        
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    render(){
        return(
            <div className="kontakt_container">
                <h1 className="kontakt_title">Kontakt</h1>
                <Line />
                <div className="formular_container">
                    <div className="kontakt_formular">
                        <div className="kontakt_formular_subcontainer">
                            <FormInput id="name" labelname="Name*" type="text" value={this.state.name} handleInput={this.handleInput}/>
                            <FormInput id="vorname" labelname="Vorname*" type="text" value={this.state.vorname} handleInput={this.handleInput}/>
                            <FormInput id="firma" labelname="Firma" type="text" value={this.state.firma} handleInput={this.handleInput}/>
                            <FormInput id="funktion" labelname="Funktion" type="text" value={this.state.funktion} handleInput={this.handleInput}/>
                            <FormInput id="email" labelname="Email*" type="email" value={this.state.email} handleInput={this.handleInput}/>
                        </div>
                        <div className="kontakt_formular_subcontainer">
                            <FormInput id="nachricht" labelname="Nachricht*" type="textarea" value={this.state.nachricht} handleInput={this.handleInput}/>
                            <FormButton onClick={this.handleclicks} text="Mitteilung versenden"/>
                        </div>
                    </div>
                </div> 
                <div className={"kontakt_toast " + this.state.toaststatus}>
                    <p>{this.state.toastmessage}</p>
                </div>
            </div>
        );
    }
}

export default kontakt;