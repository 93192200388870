import React from 'react';
import "./Module.css";

import { Link } from 'react-router-dom';

import Loupe from '../../../Bilder/icons/loupe.svg';

const modul = (props) =>{
    return(
        <Link className="modul_link" /*to={"/Module/" + props.link}*/>
            <div className="modul_container_module">
                <div className="modul_circle">
                    <img className="modul_image_circle" src={props.src} alt={props.title}/>
                </div>
                <div className="modul_search">
                    <img className="modul_loupe_icon" src={Loupe} alt="loupe"/>
                </div>
                <h2 className="module_image_title">{props.title}</h2>
                <p className="module_image_subtitle">{props.subtitle}</p>
            </div>
        </Link>
    );
}

export default modul;