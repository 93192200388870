import React from 'react';
import "./Line.css";

const line = (props) =>{
    return(
        <div className="line">
            <div className="red_line"></div>
            <div className="grey_line"></div>
        </div>
    );
}

export default line;