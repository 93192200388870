import React, { useEffect } from 'react';
import Aos from 'aos';
import "aos/dist/aos.css";

import "./Home.css";

import Abacuslogo from '../../Bilder/01_Abacus_Logo_RGB.png';

import Rekrutierung from '../../Bilder/Module/Rekrutierung.svg';
import Payroll from '../../Bilder/Module/Payroll.svg';
import Dossier from '../../Bilder/Module/Dossier.svg';
import Budget from '../../Bilder/Module/Budget.svg';
import Zeugnis from '../../Bilder/Module/Zeugnis.svg';
import Prozesse from '../../Bilder/Module/Prozesse.svg';
import Zeit from '../../Bilder/Module/Zeit.svg';
import Spesen from '../../Bilder/Module/Spesen.svg';
import Gespraech from '../../Bilder/Module/Gespraech.svg';
import ESSMSS from '../../Bilder/Module/ESSMSS.svg';
import Kompetenz from '../../Bilder/Module/Kompetenz.svg';
import Verguetung from '../../Bilder/Module/Verguetung.svg';

import hauptBild1 from '../../Bilder/Illustration_Human_Resources_iPad_iMac_iPhone_DE.png';
//import homeBild from '../../Bilder/Home/Home1.png';
//import homeBild2 from '../../Bilder/Illustration_AbaClik3_FR.png';

import home_video1 from '../../Video/Abacus HR in der Cloud.mov';
import Line from '../../Line/Line';
import Module from './Module/Module';
//import Carousel from '../../carousel/carousel';
import Vidplayer from './Video/Video';

const Home = (props) =>{
    useEffect(() =>{
        Aos.init({
            duration: 2000,
            once: true,
        });
    }, []);

    return(
        <div className="Home_container">
            {/*<Carousel images={carousel} />*/}
            {/*<div className="home_picture_background" style={{ backgroundImage: "url(" + homeBild + ")"}} />*/}
            <Vidplayer src={home_video1} type="video/mp4"/>
            {/*<div className="home_picture_background2" style={{ backgroundImage: "url(" + homeBild2 + ")"}} />*/}
            {/*<img className="home_main_picture" src={homeBild} alt="Abacus HR aus der CH Cloud"/>*/}
            <div className="home_h1_title_container">
                <img src={Abacuslogo} alt="abacuslogo" width="220px" height="40.59px"/>
                <h1 className="home_h1_title">HR - in der schweizer Cloud</h1>
            </div>
            <div className="modules_container_background">
                <div className="home_title_container">
                    <h2 className="home_h1_title2">Module</h2>
                    <Line />
                </div>
                <div className="modules_container">
                    <div data-aos="fade-up"><Module src={Rekrutierung} title="Rekrutierung" link="Rekrutierung"/></div>
                    <div data-aos="fade-up" data-aos-delay="100"><Module src={Payroll} title="Payroll" link="Payroll" subtitle="Abacus Payroll"/></div>
                    <div data-aos="fade-up" data-aos-delay="200"><Module src={Dossier} title="Dossier" link="Dossier"/></div>
                    <div data-aos="fade-up"><Module src={Budget} title="Budget" link="Budget"/></div>
                    <div data-aos="fade-up" data-aos-delay="100"><Module src={Zeugnis} title="Zeugnis" link="Zeugnis"/></div>
                    <div data-aos="fade-up" data-aos-delay="200"><Module src={Prozesse} title="Prozesse" link="Prozesse"/></div>
                    <div data-aos="fade-up"><Module src={Zeit} title="Zeit" link="Zeit"/></div>
                    <div data-aos="fade-up" data-aos-delay="100"><Module src={Spesen} title="Spesen" link="Spesen"/></div>
                    <div data-aos="fade-up" data-aos-delay="200"><Module src={Gespraech} title="Gespräch" link="Gespraech"/></div>
                    <div data-aos="fade-up"><Module src={ESSMSS} title="ESS MSS" link="ESSMSS"/></div>
                    <div data-aos="fade-up" data-aos-delay="100"><Module src={Kompetenz} title="Kompetenz" link="Kompetenz"/></div>
                    <div data-aos="fade-up" data-aos-delay="200"><Module src={Verguetung} title="Vergütung" link="Verguetung"/></div>
                </div>
            </div>
            <div data-aos="fade-up">
                <h2 className="Home_Title">«Sind Ihre Mitarbeiter noch in Excel Tabellen gespeichert oder arbeiten Sie schon mit Abacus HR aus der Cloud»</h2>
                <Line />
            </div>
            <div className="home_container1">
                <div>
                    <h2 data-aos="fade-up" className="Home_Title2">Warum könnte Abacus HR aus der Cloud für Sie Sinn machen:</h2>
                    <ul data-aos="fade-up" className="Home_list">
                        <li>Sofort loslegen ohne grosse Investitionen in eigene Infrastruktur(en)</li>
                        <li>Abacus HR schliesst alle Mitarbeitenden in den Prozess mit ein (Portal, Handy, etc.)</li>
                        <li>Abacus HR vereint viele HR Prozesse die Sie modular einsetzen können</li>
                        <li>Keine mühsamen Schnittstellen in andere Systeme (z.B. Zeit oder Bewerber)</li>
                        <li>Payroll und Zeit (und viele andere Prozesse) aus dem gleichen System gibt’s nur bei Abacus</li>
                    </ul>
                    <p data-aos="fade-up">Wir freuen uns auf Sie</p>           
                </div>
                <img data-aos="fade-up" className="Home_hauptbild1" src={hauptBild1} alt="Hauptseite_Bild1"/>
            </div>

        </div>
    );
}

export default Home;