import React from 'react';
import './Toolbar.css';
import SideDrawerToggleButton from '../Sidedrawer/SideDrawerToggleButton';
import { Link } from 'react-router-dom';
//import Dropdown from '../../Dropdown/dropdown';

import Logo from '../../Bilder/lepa_logo.png'

const toolbar = props => {
    /*const links = [
        {name: "Rekrutierung", link: "Rekrutierung"},
        {name: "Payroll", link: "Payroll"},
        {name: "Dossier", link: "Dossier"},
        {name: "Budget", link: "Budget"},
        {name: "Zeugnis", link: "Zeugnis"},
        {name: "Prozesse", link: "Prozesse"},
        {name: "Zeit", link: "Zeit"},
        {name: "Spesen", link: "Spesen"},
        {name: "Gespräch", link: "Gespraech"},
        {name: "ESS MSS", link: "ESSMSS"},
        {name: "Kompetenz", link: "Kompetenz"},
        {name: "Vergütung", link: "Verguetung"},
    ]*/
    return(
        <header className="toolbar">
            <nav className="toolbar_navigation">
                <div className="toolbar_toggleButton">
                    <SideDrawerToggleButton click={props.drawerClickHandler}/>
                </div>
                <div className="toolbar_logo">
                    <Link className="toolbar_logo_link" to="/">
                        <img className="toolbar_image" src={Logo} alt="home"/>
                    </Link>
                </div>
                <div className="spacer" />
                <div className="toolbar_navigation-item">
                    <ul>
                        {/*<li><Dropdown name="Module" links={links}/></li>*/}
                        {/*<li><Link className="link" to="/Module" >Module</Link></li>*/}
                        <li><Link className="link" to="/AbacusHR" >Abacus HR</Link></li>
                        <li><Link className="link" to="/Kontakt" >Kontakt</Link></li>
                    </ul>
                </div>
            </nav>
        </header> 
    )
}

export default toolbar;