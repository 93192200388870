import React from 'react';
import "./FormInput.css";

const forminput = (props) =>{

    if(props.type !== "textarea"){
        return(
            <div className="form_container">
                <label for={props.id}>{props.labelname}</label>
                <input type={props.type} id={props.id} name={props.id} value={props.value} onChange={props.handleInput}></input>
            </div>
        );
    }
    else{
        return(
            <div className="form_container_text">
                <label for={props.id}>{props.labelname}</label>
                <textarea type={props.type} id={props.id} name={props.id} value={props.value} onChange={props.handleInput}></textarea>
            </div>
        );
    }
}

export default forminput;