import React, {Component} from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';

import './App.css';

import Toolbar from './Barcomponents/Toolbar/Toolbar';
import SideDrawer from './Barcomponents/Sidedrawer/SideDrawer';
import Backdrop from './Barcomponents/Backdrop/Backdrop';
import Footer from './Footer/Footer';

import Kontakt from './Pages/Kontakt/Kontakt';
import Home from './Pages/Home/Home';
import Module from './Pages/Module/Module';

import Rekrutierung from './Pages/Modules/Rekrutierung';
import Payroll from './Pages/Modules/Payroll';
import Dossier from './Pages/Modules/Dossier';
import Budget from './Pages/Modules/Budget';
import Zeugnis from './Pages/Modules/Zeugnis';
import Prozesse from './Pages/Modules/Prozesse';
import Zeit from './Pages/Modules/Zeit';
import Spesen from './Pages/Modules/Spesen';
import Gespraech from './Pages/Modules/Gespraech';
import ESSMSS from './Pages/Modules/ESSMSS';
import Kompetenz from './Pages/Modules/Kompetenz';
import Verguetung from './Pages/Modules/Verguetung';

import AbacusHR from './Pages/Modules/AbacusHR';

class App extends Component {
  state = {
    sideDrawerOpen: false,
  }

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return{sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  };

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false})
  };

  render(){

    let backdrop;

    if(this.state.sideDrawerOpen){
      backdrop = <Backdrop click={this.backdropClickHandler}/>;
    }

    const NotFound = () =>{
      return <div className="p404"><h3>404 Seite nicht gefunden</h3></div>;
    }

    return (
      <BrowserRouter>
        <div className="App">
          <Toolbar drawerClickHandler={this.drawerToggleClickHandler}/>
          <SideDrawer show={this.state.sideDrawerOpen} click={this.backdropClickHandler}/>
          {backdrop}
          <main style={{marginTop: '99px', flex: 1}}>
            <Switch>
              <Route path="/" exact render={() =>
                <Home />
              }/>
              <Route path="/Kontakt" exact render={() =>
                <Kontakt />
              }/>
              <Route path="/Module" exact render={() =>
                <Module />
              }/>
              <Route path="/Module/Rekrutierung" exact render={() =>
                <Rekrutierung />
              }/>
              <Route path="/Module/Payroll" exact render={() =>
                <Payroll />
              }/>
              <Route path="/Module/Dossier" exact render={() =>
                <Dossier />
              }/>
              <Route path="/Module/Budget" exact render={() =>
                <Budget />
              }/>
              <Route path="/Module/Zeugnis" exact render={() =>
                <Zeugnis />
              }/>
              <Route path="/Module/Prozesse" exact render={() =>
                <Prozesse />
              }/>
              <Route path="/Module/Zeit" exact render={() =>
                <Zeit />
              }/>
              <Route path="/Module/Spesen" exact render={() =>
                <Spesen />
              }/>
              <Route path="/Module/Gespraech" exact render={() =>
                <Gespraech />
              }/>
              <Route path="/Module/ESSMSS" exact render={() =>
                <ESSMSS />
              }/>
              <Route path="/Module/Kompetenz" exact render={() =>
                <Kompetenz />
              }/>
              <Route path="/Module/Verguetung" exact render={() =>
                <Verguetung />
              }/>
              <Route path="/AbacusHR" exact render={() =>
                <AbacusHR />
              }/>
              <Route path="/*">
                <NotFound />
              </Route>
            </Switch>
          </main>
          <Footer/>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
