import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

import logo from "../Bilder/lepa_logo.png";
import youtube from "../Bilder/Socials/001-youtube.svg";
import linkedin from "../Bilder/Socials/002-linkedin.svg";

import Line from "../Line/Line";

const footer = props => (
    <footer className="footer">
        <div className="footer_content">
            <div className="content_image">
                <img className="footer_logo" src={logo} alt="logo" />
            </div>
            <div className="content">
                <h2>Nützliche Links</h2>
                <Line />
                <ul className="listlinks">
                    <li><Link className="link" to="/" >Home</Link></li>
                    <li><Link className="link" to="/AbacusHR" >Abacus HR</Link></li>
                    <li><Link className="link" to="/Kontakt" >Kontakt</Link></li>
                </ul>
            </div>
            <div className="content">
                <h2>Kontakt</h2>
                <Line />
                <p>LEHMANN+PARTNER Informatik AG</p>
                <p>Pilatusstrasse 39</p>
                <p>6003 Luzern</p>
                <div className="kontakt_links">
                    <a href="tel:+41412273070">+41 41 227 3070</a>
                </div>
                <div className="kontakt_links">
                    <a href="mailto:info@lepa.ch">info@lepa.ch</a>
                </div>
            </div>
        </div>
        <div className="footer_sub">
            <p>© 2021 – LEHMANN+PARTNER Informatik AG</p>
            <a href="https://www.youtube.com/channel/UCVVdWP90nor114fiUDQe4oA" rel="noreferrer" target="_blank">
                <img className="socials" src={youtube} alt="youtube"/>
            </a>
            <a href="https://www.linkedin.com/company/lehmannpartner" rel="noreferrer" target="_blank">
                <img className="socials" src={linkedin} alt="linkedin"/>
            </a>
        </div>
    </footer>
);

export default footer;