import React, {useState} from 'react';

import volume from '../../../Bilder/Volume/volume-high-sharp.svg'
import volume_mute from '../../../Bilder/Volume/volume-mute-sharp.svg'

import './Video.css';

const Video = (props) =>{

    const [isMuted, setIsMuted] = useState(true);

    return(
        <div className="home_video_containder">
            <video className="home_vid" onClick={() => setIsMuted(!isMuted)} id="home_video" autoPlay loop muted={isMuted}>
                <source src={props.src} type={props.type}/>
            </video>
            <button className="home_video_mute_button" onClick={() => setIsMuted(!isMuted)}>
                {isMuted ? <img className="home_video_icon" src={volume_mute} alt="muted"/> : <img className="home_video_icon" src={volume} alt="soundon"/>}
            </button>
        </div>
    );
}

export default Video;