import React from 'react';
import './SideDrawer.css';
import { Link } from 'react-router-dom';

//import Dropdown from '../../Dropdown/dropdown';

const sideDrawer = props => {
    let drawerClasses = 'side-drawer';
    if (props.show){
        drawerClasses = 'side-drawer open';
    }

    /*const links = [
        {name: "Rekrutierung", link: "Rekrutierung"},
        {name: "Payroll", link: "Payroll"},
        {name: "Dossier", link: "Dossier"},
        {name: "Budget", link: "Budget"},
        {name: "Zeugnis", link: "Zeugnis"},
        {name: "Prozesse", link: "Prozesse"},
        {name: "Zeit", link: "Zeit"},
        {name: "Spesen", link: "Spesen"},
        {name: "Gespräch", link: "Gespraech"},
        {name: "ESS MSS", link: "ESSMSS"},
        {name: "Kompetenz", link: "Kompetenz"},
        {name: "Vergütung", link: "Verguetung"},
    ]*/

    return(
    <nav className={drawerClasses}>
        <ul>
            {/*<li><Dropdown name="Module" links={links} handleClick={props.click}/></li>*/}
            {/*<li onClick={props.click}><Link className="link" to="/Module" >Module</Link></li>*/}
            <li onClick={props.click}><Link className="link" to="/AbacusHR" >Abacus HR</Link></li>
            <li onClick={props.click}><Link className="link" to="/Kontakt" >Kontakt</Link></li>
        </ul>
    </nav>
    );
};

export default sideDrawer;